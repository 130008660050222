
$white: #ffffff;


$theme-colors: (
    "light":      #73ceff,
    "dark":       #004b84,
    "primary":    #156cad,
    "secondary":  #ff99bf,
    "accent1":    #005491,
    "accent2":    #f97ead,
    "accent3":    #426e91,
    "accent4":    #53c1fd,
    "accent5":    #fdeaea,
    "accent6":    #1b47f8,
    "info":       #174e78,
    "success":    #07a05e,
    "warning":    #e0ca07,
    "danger":     #ee1f02,
);

@import "~bootstrap/scss/bootstrap";

.full-height {
  min-height: 100vh; /* Makes sure each container takes at least the full height of the viewport */
  padding: 0;        /* Removes any padding from the container */
  display: flex; /* Flexbox for vertical alignment */
  flex-direction: column; /* Stack children vertically */
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
}

body, html {
  overflow-x: hidden;
}

.montserrat-rpm {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.connect-img {
  width: 300px;  /* Set the desired width */
  height: 300px; /* Set the desired height */
  object-fit: cover; /* Ensures the image covers the entire area while maintaining aspect ratio */
  border-radius: 8px; /* Optional: Adds rounded corners */
}


.chat-box-container{
  max-width: 600px; /* Fixed max width */
  min-width: 600px; /* Fixed min width */
  @media (min-width: 350px) {
    min-width: 325px; /* Adjust width for small screens */
    max-width: 325px; /* Adjust width for small screens */
  }

  @media (min-width: 375px) {
    min-width: 350px; /* Adjust width for small screens */
    max-width: 350px; /* Adjust width for small screens */
  }
  @media (min-width: 576px) {
      min-width: 500px; /* Adjust width for small screens */
      max-width: 500px; /* Adjust width for small screens */
  }
}


/* Keep chatInputBox responsive */
#chatInputBox {
  width: 100%;          /* Matches parent width */
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 30px;
  padding: 10px 15px;
  color: #333;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  word-wrap: break-word;
  min-height: 50px;     /* Ensure minimum height */
}


#displayedText::after {
  content: '|';
  font-weight: bold;
  color: #ffffff;
  animation: blink 1s steps(2, start) infinite;
}

@keyframes blink {
  from, to {
    color: transparent;
  }
  50% {
    color: #ffffff;
  }
}

.response-text {
  border-radius: 10px;
  width: 80%;
  align-self: flex-end;
  text-align: right;
  padding: 10px 15px;
}

.circular-image {
  width: 100px; /* Default size for small screens */
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #004b84; /* Optional: Add a border */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for effect */

  @media (min-width: 576px) {
    width: 120px; /* Slightly larger for small to medium screens */
    height: 120px;
  }

  @media (min-width: 768px) {
    width: 150px; /* Medium screens */
    height: 150px;
  }

  @media (min-width: 992px) {
    width: 200px; /* Large screens */
    height: 200px;
  }

  @media (min-width: 1200px) {
    width: 250px; /* Extra large screens */
    height: 250px;
  }
}
/* Modifier class for SVGs */
.svg-no-border {
  border: none !important;
  box-shadow: none !important;
}

/* Styling for the LinkedIn icon link */
.linkedin-link {
  text-decoration: none;  /* Remove underline */
  display: inline-block;  /* Align inline with text */
  padding: 0 10px;  /* Add padding around the logo */
}

/* Style the LinkedIn logo */
.linkedin-logo {
  width: 50px;  /* Set width */
  height: auto;  /* Maintain aspect ratio */
  transition: transform 0.3s ease;  /* Add hover effect */
}

/* Hover effect */
.linkedin-link:hover .linkedin-logo {
  transform: scale(1.1);  /* Slightly enlarge the logo on hover */
}

.carousel-indicators [data-bs-target] {
  background-color: #000 !important; /* Black dots for visibility */
  opacity: 0.7; /* Slight transparency */
  width: 8px; /* Larger dots */
  height: 8px;
  border-radius: 50%; /* Make them circular */
  margin: 0 5px; /* Space them out */
}

.carousel-indicators .active {
  background-color: #1b47f8 !important; /* Highlight active indicator in blue */
  opacity: 1; /* Fully opaque when active */
}